



<template>

    <Section :options="{section:false}" id="honoraires" class="p-0 mt-5">

          <div class="row mb-0">

						<div class="col-12">

							<div class="heading-block center border-bottom-0">
								<h2>Nos honoraires</h2>
								<span>Conformément au Règlement Intérieur National de la profession d’Avocat, les honoraires sont fixés de la manière suivante</span>
							</div>

              <!--
              <div v-swiper="swiperOption" class="swiper slider-inner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <img src="@public/img/bensadevot/tableaux/scene-01.jpg" />
                    </div>
                    <div class="swiper-slide">
                      <img src="@public/img/bensadevot/tableaux/scene-02.jpg" />
                    </div>
                    <div class="swiper-slide">
                      <img src="@public/img/bensadevot/tableaux/scene-03.jpg" />
                    </div>
                    <div class="swiper-slide">
                      <img src="@public/img/bensadevot/tableaux/scene-04.jpg" />
                    </div>
                </div>
                <div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
				        <div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
              </div>
              -->

						</div>

            <div class="col-12">

              <h3>Article 11 - Honoraires – émoluments – débours – mode de paiement des honoraires</h3>
              <h4>11.1 Information du client</h4>
              <p>
              L’avocat informe son client, dès sa saisine, des modalités de détermination des honoraires et l’informe régulièrement de l’évolution de leur montant. L’avocat informe également son client de l’ensemble des frais, débours et émoluments qu’il pourrait exposer.
              </p>
              <h4>11.2 Convention d’honoraires</h4>
              <p>
              Sauf en cas d’urgence ou de force majeure ou lorsqu’il intervient au titre de l’aide juridictionnelle totale ou de la troisième partie de la loi n° 91-647 du 10 juillet 1991 relative à l’aide juridique, l’avocat conclut par écrit avec son client une convention d’honoraires, qui précise, notamment, le montant ou le mode de détermination des honoraires couvrant les diligences prévisibles, ainsi que les divers frais et débours envisagés.
              </p>
              <h5>Détermination des honoraires</h5>
              <p>
              Les honoraires sont fixés selon les usages, en fonction de la situation de fortune du client, de la difficulté de l’affaire, des frais exposés par l’avocat, de sa notoriété et des diligences de celui-ci. L’avocat chargé d’un dossier peut demander des honoraires à son client même si ce dossier lui est retiré avant sa conclusion, dans la mesure du travail accompli.
              </p>
              <h5>Eléments de la rémunération</h5>
              <p class="mb-0">
              La rémunération de l’avocat est fonction, notamment, de chacun des éléments suivants conformément aux usages :
              </p>
              <ul class="ps-5">
                <li>le temps consacré à l’affaire,</li>
                <li>le travail de recherche,</li>
                <li>la nature et la difficulté de l’affaire,</li>
                <li>l’importance des intérêts en cause,</li>
                <li>l’incidence des frais et charges du cabinet auquel il appartient,</li>
                <li>sa notoriété, ses titres, son ancienneté, son expérience et la spécialisation dont il est titulaire,</li>
                <li>les avantages et le résultat obtenus au profit du client par son travail, ainsi que le service rendu à celui-ci,</li>
                <li>la situation de fortune du client.</li>
              </ul>

              <h4>11.3 Modes prohibés de rémunération</h4>
              <p>
              Il est interdit à l’avocat de fixer ses honoraires par un pacte de quota litis.
              </p>
              <p>
              Le pacte de quota litis est une convention passée entre l’avocat et son client avant décision judiciaire définitive, qui fixe exclusivement l’intégralité de ses honoraires en fonction du résultat judiciaire de l’affaire, que ces honoraires consistent en une somme d’argent ou en tout autre bien ou valeur.
              </p>
              <p>
              L’avocat ne peut percevoir d’honoraires que de son client ou d’un mandataire de celui-ci.
              </p>
              <p>
              La rémunération d’apports d’affaires est interdite.
              </p>
              <h4>11.5 Modes de règlement des honoraires</h4> 
              <p>
              Les honoraires sont payés dans les conditions prévues par la loi et les règlements, notamment en espèces, par chèque, par virement, par billet à ordre et par carte bancaire.
              </p>
              <p>
              L’avocat peut recevoir un paiement par lettre de change dès lors que celle-ci est acceptée par le tiré, client de l’avocat.
              </p>
              <p>
              L’endossement ne peut être fait qu’au profit de la banque de l’avocat, aux seules fins d’encaissement.
              </p>
              <p>
              L’avocat porteur d’une lettre de change impayée peut agir devant le Tribunal de Commerce. Toutefois, en cas de contestation de la créance d’honoraires, il devra saisir son bâtonnier aux fins de taxation et solliciter le sursis à statuer devant la juridiction commerciale.
              </p>
              <h4>11.6 Provision sur frais et honoraires</h4>
              <p>
              Art. 11.6 Provision sur frais et honoraires
              </p>
              <p>
              L’avocat qui accepte la charge d’un dossier peut demander à son client le versement préalable d’une provision à valoir sur ses frais et honoraires.
              </p>
              <p>
              Cette provision ne peut aller au-delà d’une estimation raisonnable des honoraires et des débours probables entraînés par le dossier.
              </p>
              <p>
              A défaut de paiement de la provision demandée, l’avocat peut renoncer à s’occuper de l’affaire ou s’en retirer dans les conditions prévues à l’article 13 du décret du 12 juillet 2005. Il fournit à son client toute information nécessaire à cet effet. »
              </p>
            </div>

          </div>

    </Section>
    
</template>

<style>

#honoraires .swiper {
  height: auto;
  max-width: 600px;
}

#honoraires .swiper .swiper-slide {
  text-align: center;
}

#honoraires .swiper .swiper-slide img {
  height:300px;
}
    

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';

// Import Swiper Vue.js components
import { SwiperOptions} from 'swiper';
import { getSwiperOptionsOn } from '@root/src/client/theme/canvas-664/canvas.slider';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Honoraires extends Vue {

  swiperOption:SwiperOptions = {
            //slidesPerView : 3,
            //spaceBetween : 50
        navigation: {
            nextEl: '.slider-arrow-right',
            prevEl: '.slider-arrow-left',
        },
        speed: 500,
        autoplay: {
            delay: 5000
        },
        on: getSwiperOptionsOn({} as HTMLElement)
    }

  mounted() {
    
  }
    
}
</script>